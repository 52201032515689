import type { ReactNode } from "react";

interface Props {
	children: ReactNode;
	isSpect?: boolean;
}
function GameWrapper({ children, isSpect = false }: Props) {
	return (
		<div
			className={`w-full font-Ubuntu  h-svh flex justify-center items-center ${isSpect ? "bg-slate-800" : "bg-white"} overflow-hidden`}
		>
			<div className="lg:w-[400px] relative w-full h-full">{children}</div>
		</div>
	);
}

export default GameWrapper;
