import GameWrapper from "@/components/GameWrapper";
import UseDevicePixelRatio from "@/hooks/UseDevicePixelRatio";
import { Unity } from "react-unity-webgl";
import { useBackgammon } from "./hooks";
import { useParams } from "@tanstack/react-router";
import NetworkStatusText from "@/components/NetworkStatusText/intex";
import GameProgressBar from "./GameProgressBar";

function Backgammon() {
	const { matchID, token } = useParams({
		from: "/backgammon/$token/$matchID",
	});

	const { devicePixelRatio } = UseDevicePixelRatio();
	const { isLoaded, loadingPercentage, unityProvider, isConnected } =
		useBackgammon({
			matchID,
			token,
		});
	return (
		<GameWrapper>
			{isLoaded && !isConnected && (
				<NetworkStatusText text="Waiting for network..." />
			)}
			{!isLoaded && <GameProgressBar percent={loadingPercentage} />}
			{isConnected && (
				<Unity
					className="size-full"
					devicePixelRatio={devicePixelRatio}
					unityProvider={unityProvider}
				/>
			)}
		</GameWrapper>
	);
}

export default Backgammon;
