import {
	type BackgammonBoardData,
	type BackgammonPlayer,
	BackgammonTurnsVariants,
} from "../types";

export function SocketStartPointToUnityTurn(
	startPoint: number
): BackgammonTurnsVariants {
	switch (startPoint) {
		case 0:
			return BackgammonTurnsVariants.WHITE;
		case 23:
			return BackgammonTurnsVariants.BLACK;

		default:
			return BackgammonTurnsVariants.WHITE;
	}
}
export const SocketMatchDataToBackgammonBoardData = (
	socketData: BackgammonBoardData
) => {
	let currentPlayer: BackgammonPlayer | undefined;
	const data: BackgammonBoardData = {
		...socketData,
		players: socketData.players.map((player) => {
			if (player.playerId === socketData.current_user_id) {
				currentPlayer = player;
			}
			return {
				...player,
				checkers: player.checkers.sort(
					(a, b) => a.checkerIndex - b.checkerIndex
				),
				color: SocketStartPointToUnityTurn(player.startingHouse),
			};
		}),
		currentPlayerColor: SocketStartPointToUnityTurn(
			currentPlayer?.startingHouse ?? 0
		),
	};
	return data;
};
export const getSquadFromPlayerId = (
	id: number,
	boardData: BackgammonBoardData
) => {
	return (
		boardData.players.find((player) => player.playerId === id)?.color ?? ""
	);
};
