/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUnityContext } from "react-unity-webgl";
import type { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import {
	type DiceData,
	type Player,
	type SocketActionData,
	type SocketHitData,
	type SocketMoveData,
	type SocketResultData,
	type SocketTurnData,
	type BoardData,
	LudoSocketEventVariant,
	unityEvents,
	unityGameObjects,
} from "../../Types";

import {
	sendMessageToReactNative,
	SocketMatchDataToBoardData,
	SocketStartPointToUnityTurn,
} from "../../helpers";
import { unityActions } from "../../Constants";
import { useAblyRealtime } from "@/hooks";

interface Props {
	matchID: string;
	clientId: string;
}

function useLudoSpectator({ matchID, clientId }: Props) {
	const {
		unityProvider,
		loadingProgression,
		isLoaded,
		addEventListener,
		removeEventListener,
		sendMessage,
	} = useUnityContext({
		loaderUrl: "/games/Ludo-1/ludo.loader.js",
		dataUrl: "/games/Ludo-1/ludo.data.br",
		frameworkUrl: "/games/Ludo-1/ludo.framework.js.br",
		codeUrl: "/games/Ludo-1/ludo.wasm.br",
	});
	const [initialized, setInitialized] = useState(false);
	const [isConnected, setIsConnected] = useState(false);
	const [boardData, setBoardData] = useState<BoardData | undefined>(undefined);
	const {
		MatchPresentsHandler,
		clientChannel,
		matchEventsChannel,
		matchRequestChannel,
		realtime,
	} = useAblyRealtime({
		clientID: clientId,
		matchID: matchID,
	});
	const loadingPercentage = useMemo(
		() => Math.round(loadingProgression * 100),
		[loadingProgression]
	);

	const getProfile = (id: number) => {
		try {
			const profile = boardData?.players_profiles.find(
				(item) => item.user_id === id
			);
			return profile;
		} catch (error) {
			console.log(error);
			setInitialized(false);
			sendMessageToReactNative("spectatorInvalid", {});

			throw error;
		}
	};

	const HandleSocketMove = useCallback(
		(socketData: SocketActionData<SocketMoveData>) => {
			if (boardData) {
				const player = boardData?.players.find(
					(player) => player.playerId === socketData.data.playerId
				);

				sendMessage(
					player?.squad ?? "",
					"MovePlayerById",
					socketData.data.pieceId.toString()
				);
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketTurnUpdate = useCallback(
		(playerId: number, timer = 60) => {
			try {
				if (boardData) {
					setTimeout(() => {
						const player = boardData?.players.find(
							(player) => player.playerId === playerId
						);

						const SelectedTurn = SocketStartPointToUnityTurn(
							player?.startPoint ?? 0
						);

						sendMessage(
							unityGameObjects.GAME_MANAGER,
							unityActions.gameHandlers.changeTurn,
							SelectedTurn
						);
						sendMessage(unityGameObjects.DICE_BUTTON, "DeactivateDice");
						sendMessage(unityGameObjects.GAME_MANAGER, "TurnAllLightsOff");
						sendMessage(unityGameObjects.DICE_BUTTON, "SetTimer", timer);
						sendMessage(player?.squad ?? "", "SetLightOn");
					}, 1200);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketHit = useCallback(
		(HitData: SocketActionData<SocketHitData>) => {
			if (boardData) {
				const player = boardData?.players.find(
					(player) => player.playerId === HitData.data.playerId
				);
				setTimeout(() => {
					sendMessage(
						player?.squad ?? "",
						unityActions.squadHandlers.HitPacesByID,
						HitData.data.pieceId.toString()
					);
				}, 700);
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketTimeOut = useCallback(
		(currentTurnPlayer: Player) => {
			if (boardData) {
				const player = boardData?.players.find(
					(player) => player.playerId === currentTurnPlayer.playerId
				);
				sendMessage(
					player?.squad ?? "",
					"SetTimeOuts",
					currentTurnPlayer.faultCount
				);
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketWon = useCallback(
		(resultData: SocketActionData<SocketResultData>) => {
			if (boardData) {
				const winner = resultData.data.results.find(
					(player) => player.status === "Won"
				);
				const player = boardData?.players.find(
					(player) => player.playerId === winner?.player_id
				);
				sendMessage(player?.squad ?? "", unityActions.squadHandlers.WonHandler);

				sendMessage(unityGameObjects.DICE_BUTTON, "ResetTimer");
				sendMessageToReactNative("endGame", {
					...resultData.data,
				});
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketLeft = useCallback(
		(playerData: SocketActionData<Player>) => {
			if (boardData) {
				const player = boardData?.players.find(
					(player) => player.playerId === playerData.data.playerId
				);
				sendMessage(player?.squad ?? "", "DeactivateSquad");
			}
		},
		[boardData, sendMessage]
	);
	const HandleProfile = useCallback(
		(...parameters: Array<ReactUnityEventParameter>) => {
			const [userId] = parameters;
			sendMessageToReactNative("profileClicked", {
				userId,
			});
		},
		[]
	);
	useEffect(() => {
		if (isLoaded) {
			addEventListener(unityEvents.Profile_Clicked, HandleProfile);
		}
		return () => {
			removeEventListener(unityEvents.Profile_Clicked, HandleProfile);
		};
	}, [addEventListener, removeEventListener, HandleProfile, isLoaded]);
	const MatchRequestHandler = useCallback(async () => {
		await clientChannel.presence.enter();
		await MatchPresentsHandler();
		await clientChannel.subscribe((message) => {
			const data = message.data as BoardData;
			const normalizedData = SocketMatchDataToBoardData({
				...data,
				current_user_id: 0,
			});
			setBoardData(normalizedData);
		});
		await matchRequestChannel.presence.enter();
		await matchRequestChannel.publish(
			"get-match-data",
			JSON.stringify({
				matchId: matchID,
				clientId,
				gameType: "Ludo",
			})
		);
	}, [matchRequestChannel, clientChannel]);
	const MatchEventsHandler = useCallback(async () => {
		await matchEventsChannel.presence.enter();
		await matchEventsChannel.subscribe((message) => {
			const socketData = message.data;

			switch ((socketData as SocketActionData).action) {
				case LudoSocketEventVariant.ROLLED_DICE: {
					const diceData = socketData as SocketActionData<DiceData>;

					sendMessage(
						unityGameObjects.DICE_BUTTON,
						unityActions.DiceHandler.changeDiceNumber,
						diceData.data.diceValue
					);

					break;
				}
				case LudoSocketEventVariant.TURN_UPDATE: {
					const TurnData = socketData as SocketActionData<SocketTurnData>;

					HandleSocketTurnUpdate(TurnData.data.currentTurnPlayer.playerId);

					break;
				}
				case LudoSocketEventVariant.PLAYER_MOVE: {
					const TurnData = socketData as SocketActionData<SocketMoveData>;

					HandleSocketMove(TurnData);

					break;
				}
				case LudoSocketEventVariant.PLAYER_HIT: {
					const TurnData = socketData as SocketActionData<SocketMoveData>;

					HandleSocketHit(TurnData);

					break;
				}
				case LudoSocketEventVariant.TIME_OUT: {
					const TurnData = socketData as SocketActionData<Player>;
					HandleSocketTimeOut(TurnData.data);
					break;
				}
				case LudoSocketEventVariant.END_GAME: {
					const gameResult = socketData as SocketActionData<SocketResultData>;
					HandleSocketWon(gameResult);
					break;
				}
				case LudoSocketEventVariant.PLAYER_LEFT: {
					const playerData = socketData as SocketActionData<Player>;

					HandleSocketLeft(playerData);

					break;
				}

				default:
					break;
			}
		});
	}, [matchEventsChannel, sendMessage, boardData, initialized]);

	useEffect(() => {
		if (isLoaded) {
			MatchRequestHandler();
		}
	}, [isLoaded]);
	useEffect(() => {
		if (initialized) {
			MatchEventsHandler();
		}
	}, [initialized]);

	// initializer
	useEffect(() => {
		if (isLoaded && boardData && !initialized) {
			sendMessage(
				unityGameObjects.GAME_MANAGER,
				"InitializeBoard",
				boardData.currentPlayerColor
			);
			sendMessage(
				unityGameObjects.GAME_MANAGER,
				"ActiveSquads",
				boardData.players.length
			);

			boardData.players.map((player) => {
				const piceList = player.pieces
					.map((item) => {
						if (item.pieceStatus === "OUT") return "OUT";
						if (item.pieceStatus === "WON") return "56";
						if (
							item.pieceStatus === "PLAYING" &&
							item.position?.type === "SIDE_ROAD"
						)
							return item.position?.index + 51;

						return item.position?.index;
					})
					.join(",");

				sendMessage(player.squad ?? "", "SquadInitializer", piceList);
				const profile = getProfile(player.playerId);
				sendMessage(
					player.squad ?? "",
					"ActiveProfile",
					JSON.stringify({
						...profile,
						profile_image: profile?.profile_image.replace(".svg", ".png"),
						TimeOutsCount: player.faultCount,
						username: profile?.full_name ?? profile?.username,
					})
				);
			});

			const timer = Math.floor(boardData.remainingTime / 1000);

			HandleSocketTurnUpdate(boardData.LastTurn.playerId, timer);
			sendMessageToReactNative("gameStarted", {});
			setInitialized(true);
		}
	}, [isLoaded, boardData, sendMessage, initialized]);
	useEffect(() => {
		function onDisconnect() {
			setIsConnected(false);
			setInitialized(false);
			setBoardData(undefined);
			console.log("socket disconnected");
		}
		function onConnect() {
			setIsConnected(true);
			console.log("connected");
		}

		realtime.connection.on("connected", onConnect);

		realtime.connection.on("disconnected", onDisconnect);

		return () => {
			realtime.connection.off();
		};
	}, [realtime]);
	return {
		loadingPercentage,
		unityProvider,
		isLoaded,
		initialized,
		isConnected,
	};
}
export default useLudoSpectator;
