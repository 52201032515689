import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import * as Sentry from "@sentry/react";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import "./index.css";

// Create a new router instance

const router = createRouter({ routeTree });
Sentry.init({
	dsn: import.meta.env["VITE_SENTRY_DSN"],
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
});

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(<RouterProvider router={router} />);
}
