import GameWrapper from "@/components/GameWrapper";
import NetworkStatusText from "@/components/NetworkStatusText/intex";
import GameProgressBar from "../../GameProgressBar";
import { Unity } from "react-unity-webgl";
import { useLudo } from "../../Hooks";
import UseDevicePixelRatio from "@/hooks/UseDevicePixelRatio";

interface Props {
	matchID: string;
	token: string;
	clientId: string;
}

function PlayerView({ matchID, token, clientId }: Props) {
	const { isLoaded, loadingPercentage, unityProvider, isConnected } = useLudo({
		matchID,
		token,
		clientId,
	});
	const { devicePixelRatio } = UseDevicePixelRatio();
	return (
		<GameWrapper>
			{isLoaded && !isConnected && (
				<NetworkStatusText text="Waiting for network..." />
			)}
			{!isLoaded && <GameProgressBar percent={loadingPercentage} />}
			{isConnected && (
				<Unity
					className="size-full"
					devicePixelRatio={devicePixelRatio}
					unityProvider={unityProvider}
				/>
			)}
		</GameWrapper>
	);
}

export default PlayerView;
