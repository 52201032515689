import { MatchData, Profile } from "@/types/global";

declare global {
	interface Window {
		ReactNativeWebView?: {
			postMessage: (message: string) => void;
		};
	}
}

export const enum LudoTurnsVariants {
	RED = "Red",
	BLUE = "Blue",
	GREEN = "Green",
	YELLOW = "Yellow",
	NONE = "None",
}
export const enum LudoSquadVariants {
	RED = "RedPlayerSquad",
	BLUE = "BluePlayerSquad",
	GREEN = "GreenPlayerSquad",
	YELLOW = "YellowPlayerSquad",
	NONE = "None",
}
export const enum unityEvents {
	DICE_ROLLED = "DiceRoll",
	PLAYER_MOVED = "PlayerMove",
	Profile_Clicked = "ProfileClicked",
	TURN_CHANGED = "TurnChanged",
}
export const enum unityGameObjects {
	GAME_MANAGER = "GameManager",
	DICE_BUTTON = "Dice Button",
	// SQUAD_GREEN = LudoSquadVariants.GREEN,
	// SQUAD_RED = LudoSquadVariants.RED,
	// SQUAD_BLUE = LudoSquadVariants.BLUE,
	// SQUAD_YELLOW = LudoSquadVariants.YELLOW,
	YELLOW_PLAYER_1 = "player Yellow 1",
	YELLOW_PLAYER_2 = "player Yellow 2",
	YELLOW_PLAYER_3 = "player Yellow 3",
	YELLOW_PLAYER_4 = "player Yellow 4",

	RED_PLAYER_1 = "Player Red 1",
	RED_PLAYER_2 = "Player Red 2",
	RED_PLAYER_3 = "Player Red 3",
	RED_PLAYER_4 = "Player Red 4",

	GREEN_PLAYER_1 = "player Green 1",
	GREEN_PLAYER_2 = "player Green 2",
	GREEN_PLAYER_3 = "player Green 3",
	GREEN_PLAYER_4 = "player Green 4",

	BLUE_PLAYER_1 = "Player Blue 1",
	BLUE_PLAYER_2 = "Player Blue 2",
	BLUE_PLAYER_3 = "Player Blue 3",
	BLUE_PLAYER_4 = "Player Blue 4",
}
export const enum LudoSocketEventVariant {
	ROLLED_DICE = "ludo.rolled_dice",
	TURN_UPDATE = "ludo.tune_updated",
	PLAYER_MOVE = "ludo.piece_moved",
	PLAYER_HIT = "ludo.piece_captured",
	TIME_OUT = "ludo.turn_timed_out",
	END_GAME = "engine.match_result",
	PLAYER_LEFT = "engine.player_left",
}
export type LudoTurnsType =
	(typeof LudoTurnsVariants)[keyof typeof LudoTurnsVariants];
export type LudoSquadType =
	(typeof LudoSquadVariants)[keyof typeof LudoSquadVariants];

export interface LastTurn {
	playerId: number;
}
export interface Position {
	type: string;
	index: number;
}
export interface Piece {
	pieceStatus: string;
	position: Position | null;
	id: number;
}

export interface Player {
	playerId: number;
	isLeftTheGame: boolean;
	startPoint: number;
	endPoint: number;
	pieces: Array<Piece>;
	faultCount: number;
	squad?: LudoSquadType;
	isConnect: boolean;
}

export interface BoardData {
	id: string;
	stateVersion: number;
	playersAreReady: boolean;
	players: Array<Player>;
	continuousSixDiceCount: number;
	LastTurn: LastTurn;
	current_user_id: number;
	currentPlayerColor: LudoTurnsVariants;
	players_profiles: Profile[];
	remainingTime: number;
}
export type LudoMatchData = MatchData<BoardData>;

export interface Option {
	pieceId: number;
	beforeMove: Position;
	afterMove: Position;
}

export interface DiceData {
	playerId: number;
	diceValue: number;
	options: Array<Option>;
	continuousSixDiceCount: number;
}
export interface SocketTurnData {
	currentTurnPlayer: Player;
}

export type LudoSocketEventType =
	(typeof LudoSocketEventVariant)[keyof typeof LudoSocketEventVariant];
export interface SocketActionData<T = undefined> {
	id: string;
	matchId: string;
	action: LudoSocketEventType;
	stateVersion: number;
	serverTime: Date;
	data: T;
}
export interface SocketMoveData {
	playerId: number;
	pieceId: number;
	move: {
		beforeMove: Position;
		afterMove: Position;
	};
}
export interface SocketHitData {
	playerId: number;
	pieceId: number;
}
export interface SocketResultData {
	results: PlayerResult[];
}

export interface PlayerResult {
	player_id: number;
	points: number;
	coins: number;
	position: number;
	status: string;
}
