import { useCallback, useMemo } from "react";
import * as Ably from "ably";
import { sendMessageToReactNative } from "@/containers/Ludo/helpers";

interface Props {
	matchID: string;
	clientID: string;
}
function UseAblyRealtime({ clientID, matchID }: Props) {
	const realtime = useMemo(
		() =>
			new Ably.Realtime({
				key: import.meta.env["VITE_ABLY_TOKEN"],
				clientId: clientID,
			}),
		[clientID]
	);
	const matchEventsChannel = realtime.channels.get(`match:${matchID}`);
	const matchRequestChannel = realtime.channels.get(`match:request`);
	const clientChannel = realtime.channels.get(`client:${clientID}`);
	const MatchPresentsHandler = useCallback(async () => {
		await matchEventsChannel.presence.subscribe(async () => {
			const presenceSet = await matchEventsChannel.presence.get();

			sendMessageToReactNative("spectatorCount", {
				presenceCount: presenceSet.length,
			});
		});
	}, [matchEventsChannel]);

	return {
		realtime,
		clientChannel,
		matchRequestChannel,
		matchEventsChannel,
		MatchPresentsHandler,
	};
}
export default UseAblyRealtime;
