import { MatchData, Profile } from "@/types/global";

export interface Position {
	type: CheckerPosition;
	index: number;
}
export interface Checker {
	id: number;
	checkerIndex: number;
	position: Position;
}
export enum CheckerPositionVariants {
	MAIN_BOARD = "MAIN_BOARD",
	BAR = "BAR",
	OFF = "BEAR_OFF",
}

export type CheckerPosition =
	(typeof CheckerPositionVariants)[keyof typeof CheckerPositionVariants];

export interface BackgammonPlayer {
	playerId: number;
	isLeftTheGame: boolean;
	isConnect: boolean;
	checkers: Checker[];
	startingHouse: number;
	endHouse: number;
	pipCount: number;
	faultCount: number;
	color: BackgammonTurnsVariants;
}
export const enum BackgammonTurnsVariants {
	BLACK = "Black Squad",
	WHITE = "White Squad",
}
export interface LastTurn {
	turnCount: number;
	step: string;
	playerId: number;
	diceValues: DiceValues[];
}

export interface InitialDice {
	playerId: number;
	diceValue: number;
}
export interface BackgammonBoardData {
	id: string;
	stateVersion: number;
	playersAreReady: boolean;
	players: Array<BackgammonPlayer>;
	continuousSixDiceCount: number;
	LastTurn: LastTurn;
	current_user_id: number;
	currentPlayerColor: BackgammonTurnsVariants;
	players_profiles: Profile[];
	remainingTime: number;
}
export type BackgammonMatchData = MatchData<BackgammonBoardData>;
export const enum unityEvents {
	DICE_ROLLED = "DiceRoll",
	PLAYER_MOVED = "PlayerMove",
	PROFILE_CLICKED = "ProfileClicked",
	DICE_SELECTED = "DiceSelected",
	UNDO = "Undo",
	CONFIRM = "Confirm",
}
export const enum BackgammonSocketEventVariant {
	ROLLED_DICE = "backgammon.rolled_dice",
	TURN_UPDATE = "backgammon.turn_updated",
	DICE_SELECT = "backgammon.dice_selected",
	PLAYER_MOVE = "backgammon.checker_moved",
	TIME_OUT = "backgammon.turn_timed_out",
	UNDO = "backgammon.move_undone",
	CAN_CONFIRM = "backgammon.request_confirm",
	END_GAME = "engine.match_result",
	PLAYER_LEFT = "engine.player_left",
}
export type BackgammonSocketEventType =
	(typeof BackgammonSocketEventVariant)[keyof typeof BackgammonSocketEventVariant];
export interface SocketActionData<T = undefined> {
	id: string;
	matchId: string;
	action: BackgammonSocketEventType;
	stateVersion: number;
	serverTime: Date;
	data: T;
}
export interface DiceValues {
	value: number;
	canMove: boolean;
}
export interface DiceData {
	playerId: number;
	diceValues: DiceValues[];
}

export interface MoveOption {
	checkerId: number;
	fromPosition: Position;
	toPosition: Position;
	playerId: number;
}
export interface SocketDiceSelectData {
	playerId: number;
	selectedDice: number;
	moveOptions: MoveOption[];
}
export interface SocketPipCountData {
	playerId: number;
	pipCount: number;
}
export interface SocketPiceMovedData {
	playerId: number;
	checkerId: number;
	selectedDice: number;
	move: MoveOption;
	pipCount: SocketPipCountData[];
}
export interface SocketPiceUndoData {
	playerId: number;
	move: {
		checkerId: number;
		selectedDice: number;
		move: MoveOption;
		hitChecker?: MoveOption;
	};
	pipCount: SocketPipCountData[];
}
export interface SocketTurnData {
	currentTurnPlayer: BackgammonPlayer;
}
export interface SocketResultData {
	results: PlayerResult[];
}

export interface PlayerResult {
	player_id: number;
	points: number;
	coins: number;
	position: number;
	status: string;
}
