import { createFileRoute } from "@tanstack/react-router";
import Ludo from "../../../containers/Ludo/index";
import type { GameModes } from "@/types/global";

type gameParameters = {
	keyName: string;
	clientId: string;
	gameMode: `${GameModes}`;
};

export const Route = createFileRoute("/ludo/$token/$matchID")({
	validateSearch: (search: Record<string, unknown>): gameParameters => {
		// validate and parse the search params into a typed state
		const clientId = (search["clientId"] as string)?.toString();
		const keyName = (search["keyName"] as string)?.toString();
		const gameMode = search["gameMode"] as GameModes;

		return {
			clientId,
			keyName,
			gameMode,
		};
	},
	component: () => {
		return <Ludo />;
	},
});
